.greyInput {
	display: flex;
	align-items: flex-start;
	padding: 18px 16px;
	height: 56px;
	background: #fafafa;
	border-radius: 8px;
	border: none;
	outline: none !important;
}

button {
	font-family: 'NotoSansGeorgianMedium', serif !important;
}

.grey {
	background: #fafafa;
	font-family: 'bpg-arial-caps-webfont', serif !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.greyInput::placeholder,
.greyTextarea::placeholder {
	color: #5f6a75;
}

.greyBg {
	background: #fafafa;
}

.greySelect {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-top: 8px;
	height: 56px;
	background: #fafafa;
	border-radius: 8px;
	border: none;
	outline: none !important;
	font-size: 12px !important;
}

.greyTextarea {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 16px;
	background: #fafafa;
	border-radius: 8px;
	border: none;
	outline: none !important;
	font-size: 12px !important;
}

.drawer-submit-area {
	background-color: #ffffff;
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 32px;
	left: 0;
	border-radius: 0 0 12px 12px;
}

.drawer-login-form-button {
	width: 100%;
	height: 55px;
}

.custom-filter-input {
	border: none !important;
	background-color: transparent !important;
	outline: none !important;
}

.select-200-min-width{
	min-width: 200px;
}
