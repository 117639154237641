@font-face {
	font-family: 'NotoSansGeorgian';
	src: url('../fonts/NotoSansGeorgian-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'bpg-web-001-caps-webfont';
	src: url('../fonts/bpg-web-001-caps-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'bpg-arial-caps-webfont';
	src: url('../fonts/bpg-arial-caps-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSansGeorgianMedium';
	src: url('../fonts/NotoSansGeorgian-Medium.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'NotoSansGeorgianBold';
	src: url('../fonts/NotoSansGeorgian-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

.NotoSansGeorgianMedium {
	font-family: NotoSansGeorgianMedium;
}

.notoSansGeorgianBold {
	font-family: NotoSansGeorgianBold;
}

#root {
	height: 100%;
}

html,
body {
	height: 100%;
	margin: 0;
	padding: 0;
	background: #f5f4f7 !important;
	font-family: 'NotoSansGeorgian', serif !important;
}

.topAffix {
	height: 24px;
	width: 100%;
	background-color: #f5f4f7;
	position: fixed;
	z-index: 999;
	top: 0;
}

.container {
	height: 100%;
	padding: 0;
}

.mainContent {
	padding: 0 24px;
}

.ant-menu,
.ant-layout,
.ant-tabs,
.ant-table,
.ant-dropdown-menu,
.ant-input,
.ant-card-head-title,
.ant-col,
.ant-select-selector {
	font-family: 'NotoSansGeorgian', serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: 'bpg-arial-caps-webfont', serif !important;
}

p {
	margin: 0;
	letter-spacing: 1px;
}

ul {
	margin: 0;
	padding: 0;
}

p,
ul,
ol {
	font-size: 12px;
}

input[type='submit'],
a,
.nbs-flexisel-nav-left,
.nbs-flexisel-nav-right {
	transition: 0.5s ease-in;
	-webkit-transition: 0.5s ease-in;
	-moz-transition: 0.5s ease-in;
	-o-transition: 0.5s ease-in;
	-ms-transition: 0.5s ease-in;
}

a:hover {
	text-decoration: none;
}

a:focus {
	outline: none;
	text-decoration: none;
}

.two-column-title {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}

.usersTable {
	margin: 20px;
}

.usersTableRow {
	display: flex;
	flex-wrap: nowrap;
	background-color: #7ba7ce;
	margin: 15px 0;
}

.usersTableRow > div {
	background-color: #f1f1f1;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 0;
	text-align: center;
	border: 1px solid #4a77d4;
	vertical-align: middle;
	line-height: 60px;
	font-size: 24px;
	font-family: cursive;
}

.usersTableRow > div img {
	width: 20%;
}

.usersTableRow > div button {
	width: 100px;
	height: 40px;
	font-family: Bahnschrift;
	text-align: center;
	font-size: 14px;
	margin-top: 10px;
	margin-left: 10px;
	line-height: 40px;
	background-color: #4a77d4;
	color: #ffffff;
	border-radius: 6px;
	border: none;
	border-bottom: 3px solid #001e35;
}

.usersTableRow > div button:disabled {
	background-color: #001e35;
}

.usersTableRow > div button:active {
	border: none;
	margin-top: 14px;
}

.selectedPage {
	font-weight: bold;
	font-size: 16px;
}

.pagination {
	display: flex;
	margin: 20px;
}

.pagination span {
	display: block;
	padding: 5px;
	cursor: pointer;
}

.twoColumnContent {
}

.twoColumnContent div {
	padding: 10px;
	border-bottom: 1px solid #eeeeee;
	margin: 10px;
	font-weight: bold;
}

.twoColumnContent div img {
	width: 100%;
}

.spinLocation {
	width: 100%;
	height: 100%;
	text-align: center;
}

.spinnn {
	margin-top: 300px;
}

.fullSpinner {
	display: grid;
	margin: auto;
}

.title {
	font-size: 26px !important;
	margin-bottom: 1em !important;
	color: rgb(22 119 255) !important;
	text-shadow: 1px 1px 1px #fff !important;
	text-transform: capitalize !important;
}

.uppercaseText {
	/*font-variant-caps: inherit;*/
}

.whiteContent {
	background-color: #ffffff !important;
	padding: 32px !important;
	border-radius: 12px;
}

.grey-bpg-web-001-caps-webfont {
	font-family: 'bpg-web-001-caps-webfont', serif !important;
	color: #5f6a75 !important;
	font-size: 13px;
}

.grey-icon {
	color: #5f6a75;
	font-size: 16px;
}

.bpg-arial-caps-webfont {
	font-family: 'bpg-arial-caps-webfont', Serif !important;
	display: flex;
	align-items: center;
}

.bpg-arial-caps-webfont span {
	margin-left: 5px;
}

.actions-list {
	transition: width 0.5s; /* Add a transition for a smooth effect */
}

.action-icon {
	color: #1677ff !important;
	font-size: 16px !important;
	cursor: pointer !important;
}

.hidden {
	display: none;
}

/*.mobile-layout {*/
/*	background-color: #ffffff;*/
/*}*/

.mobile-layout .two-column-title {
	padding: 32px;
	background-color: #ffffff;
}

.mainContent .two-column-title {
	margin-top: 24px;
}

.h44 {
	height: 44px !important;
}

.flex-wrapper {
	display: flex;
}

.empty-container {
	background-color: #ffffff !important;
	height: 80vh;
	padding: 20%;
}

.attachments {
	margin: 16px 0;
	background-color: #ffffff;
	padding: 16px;
	border-radius: 10px;
}

.col-input {
	margin-right: 16px;
}

.project-name-wrapper {
	max-width: 350px;
}

.no-wrap-grid-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 10px;
}

.collapsed-grid-wrapper {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
}

.collapsed-grid-wrapper:hover {
	cursor: pointer;
	color: #167bf2;
}

@media screen and (max-width: 767px) {
	.col-input {
		margin-right: 0px;
	}
}
