/* Margins */

.mb-16 {
	margin-bottom: 16px;
}
.mb-0 {
	margin-bottom: 0;
}
.ml-10 {
	margin-left: 10px;
}
.ml-5 {
	margin-left: 5px;
}
.mr-5 {
	margin-right: 5px;
}
/* Paddings */

.p8 {
	padding: 8px;
}

/* Font Size */

.fs8 {
	font-size: 8px;
}

.fs10 {
	font-size: 10px;
}

.fs12 {
	font-size: 12px;
}

.fs14 {
	font-size: 14px;
}
