.app {
	padding: 24px;
	min-height: 100vh;
	height: 100%;
	box-sizing: border-box;
}

.darkBg {
	background-color: #091a2b !important;
}

.darkTextGrey {
	color: #ebebeb;
}

.darkTextWhite {
	color: #ffffff;
}

.darkColorGrey {
	color: #d7d9dc !important;
}

.darkBorderGrey {
	border: 1px solid #112132 !important;
}

.ant-menu-inline {
	border: none !important;
}

.ant-menu-light {
	color: #5f6a75;
}

.ant-menu-vertical {
	border-inline-end: 0px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-drawer-content-wrapper {
	margin: 24px;
	font-family: 'NotoSansGeorgian', serif !important;
}

.ant-drawer-content {
	border-radius: 12px;
}

.ant-drawer-close {
	position: absolute;
	right: 32px;
	top: 27px;
	background-color: #fafafa !important;
	padding: 10px !important;
	margin-inline-end: 0px !important;
}

.ant-drawer-header {
	padding: 27px 32px !important;
	border-bottom: 0px solid rgba(5, 5, 5, 0.06) !important;
}

.ant-select-selector {
	background-color: inherit !important;
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	font-size: 14px !important;
}

.ant-select-selection-placeholder {
	color: #5f6a75 !important;
}

.actions-wrapper {
	padding: 10px 15px;
	background-color: #ffffff;
	border-radius: 0px 10px;
	box-shadow: 0px 1px 8px -6px #000000;
}

.actions-wrapper li {
	line-height: 2em;
}

.invoiceTabs {
	width: 100%;
}

.invoiceTabs .ant-tabs-nav {
	background-color: #fafafa !important;
}

.invoiceTabs .ant-tabs-tab-active {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 9px 21px;
	gap: 10px;
	background: #ffffff;
	box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
	border-radius: 6px;
}

.invoiceTabs .ant-tabs-tab:first-child {
	width: 48%;
}

.invoiceTabs .ant-tabs-tab:nth-child(2) {
	width: 53%;
}

.invoiceTabs .ant-tabs-nav-list {
	width: 100%;
}

.invoiceTabs .ant-tabs-tab-btn {
	outline: none;
	transition: all 0.3s;
	width: 100%;
	text-align: center;
}

.ant-menu-title-content {
	font-size: 12px;
}

.ant-collapse {
	background-color: #ffffff;
	font-family: 'NotoSansGeorgianMedium', sans-serif !important;
}

.ant-select-selection-search-input {
	height: 56px !important;
}

.ant-input {
	border-width: 0px !important;
	background-color: #fafafa !important;
}

.ant-input::placeholder {
	color: #5f6a75 !important;
}

.ant-tabs-ink-bar {
	background: inherit !important;
}
.ant-upload-list-item-name {
	width: 300px !important;
}

.two-column-title .ant-btn {
	font-size: 15px !important;
	height: 42px !important;
	line-height: 42px !important;
	padding: 0px 40px !important;
	border-radius: 6px !important;
	font-family: 'bpg-arial-caps-webfont', Serif !important;
}

.two-column-title .ant-btn a {
	display: block;
	width: 100%;
	height: 100%;
	text-align: center;
}

.ant-table-filter-column {
	text-transform: capitalize;
}

@media only screen and (max-width: 568px) {
}
