@media only screen and (max-width: 568px) {
	.topAffix {
		display: none;
	}
	.app {
		padding: 0px;
	}
	.mobileHeader {
		background-color: #ffffff;
		height: 66px;
		padding: 12px;
		display: flex;
		border-bottom: 1px solid #ededed;
	}

	.mobileMenu {
		width: 100% !important;
	}

	.burger-bar-icon {
		font-size: 16px !important;
		margin: 12px 8px;
	}

	.mainContent {
		padding: 0;
	}

	.ant-drawer-content-wrapper {
		margin: 0;
		max-width: 100%;
		left: 0;
	}

	.ant-table-thead {
		display: none;
	}
	tr {
		padding-bottom: 15px !important;
	}
	td {
		display: block;
		padding: 4px 4px !important;
	}
	.grid-wrapper {
		display: grid;
		gap: 0.5rem;
		grid-template-columns: 20ch auto;
		padding: 0.5rem 1rem;
	}

	.grid-wrapper::before {
		content: attr(custom-name);
		font-size: 12px;
		font-weight: bold;
	}

	.td-value {
		font-size: 12px;
		font-weight: normal;
	}

	.tr-spacing::after {
		content: '';
		display: block;
		height: 35px;
		background-color: #f5f5f5;
	}

	.ant-table {
		font-size: 12px !important;
	}

	.ant-table-expanded-row p {
		background-color: #ffffff;
		padding: 10px;
	}

	.ant-table-expanded-row::after {
		content: '';
		display: block;
		height: 35px;
		background-color: #f5f5f5;
	}

	.two-column-title {
		margin: -50px 16px 30px 60px !important;
	}
	.two-column-title .title {
		line-height: 34px !important;
		font-size: 15px !important;
		overflow: hidden;
		word-break: break-all;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.ant-drawer-header {
		padding: 24px 24px 16px 24px !important;
	}

	.ant-drawer-body {
		padding-top: 0px !important;
	}

	.ant-table-wrapper {
		max-width: 90%;
		margin: 5%;
	}

	.ant-menu-title-content {
		font-size: 14px;
	}

	.ant-menu-item-icon {
		font-size: 16px;
	}
	.fs10 {
		font-size: 12px;
	}

	.ant-table-row-expand-icon-cell {
		display: none;
	}

	.actions-space {
		margin: 8px 18px;
	}

	.two-column-title-origin {
		display: flex !important;
		justify-content: space-between !important;
		margin: 16px !important;
	}

	.ant-drawer-content-wrapper {
		top: 100px !important;
	}

	.whiteContent {
		margin: 16px;
		padding: 1px 0px 0px 0px !important;
	}

	.mobile-layout {
		max-width: 90%;
		margin: 0 5%;
	}

	.mobile-layout .two-column-title {
		margin: -49px 0px 30px 30px;
	}

	.ant-tabs-tab,
	.greyInput,
	.greySelect,
	.ant-select-selector {
		font-size: 12px !important;
	}

	.invoiceTabs .ant-tabs-tab {
		margin: 0 !important;
	}

	.ant-card-bordered {
		border: 0px solid #f0f0f0;
	}

	.greyInput {
		padding: 8px 8px;
		height: 40px;
	}

	.ant-select-selection-search-input {
		height: 40px !important;
	}

	.greySelect {
		height: 40px !important;
		padding-top: 0px;
	}

	.greySelect-32 {
		height: 32px !important;
		padding-top: 0px;
	}

	.attachments {
		margin: 16px;
	}
	.ant-card-body {
		padding: 16px 16px;
	}

	.padding-row {
		padding: 16px;
	}

	.ant-drawer-header {
		margin-bottom: 20px;
	}
	.third_element {
		margin: -15px 16px 5px 16px;
	}

	.third_element .greySelect {
		background-color: #ffffff;
	}

	.rowMargins {
		margin: 20px 16px 16px 22px;
	}

	.collapse-m {
		margin: 16px !important;
	}

	.ant-modal-body {
		overflow: hidden;
	}

	.empty-container {
		margin: 16px;
	}

	.col-input,
	.col-last-input {
		margin: 16px 16px 0 16px;
	}

	.col-last-input {
		margin-bottom: 16px;
	}

	.menuFixedSection {
		position: inherit !important;
	}

	.project-name-wrapper {
		max-width: inherit;
	}

	.two-column-title .ant-btn {
		padding: 0px !important;
		display: block;
		width: 42px;
		text-align: center;
		border-radius: 42px !important;
	}
}
